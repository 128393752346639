import { gql } from '@apollo/client';
import ApolloClient from './ApolloClient';

const GET_POOL_DATA = (pools) => {
  let poolString = `[`;
  pools.map((address) => {
    return (poolString += `"${address}",`);
  });
  poolString += ']';

  const queryString = `
    query pools {
      pools(where: {id_in: ${poolString}}, 
        orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
          id
          feeTier
          liquidity
          sqrtPrice
          tick
          token0 {
              id
              symbol 
              name
              decimals
              derivedETH
          }
          token1 {
              id
              symbol 
              name
              decimals
              derivedETH
          }
          token0Price
          token1Price
          volumeUSD
          txCount
          totalValueLockedToken0
          totalValueLockedToken1
          totalValueLockedUSD
        }
      }
`;
  return queryString;
};

export const getPoolData = async (chain_id, pools) => {
  const source = 'uniswapSubGraph';
  const result = await ApolloClient(chain_id, source).query({
    query: gql(GET_POOL_DATA(pools)),
  });

  return result.data;
};
