import { useSharedState } from '../context/store';
import { getPoolTick } from '../utils/utils';
import actions from '../context/actions';

import { getTopPools } from '../api/getTopPools';
import { getPoolData } from '../api/getPoolData';

export const usePools = () => {
  const [{ chain_id, provider }, dispatch] = useSharedState();

  const getUniPools = async () => {
    // We show pools on Polygon mainnet by default if no wallet is connected
    const default_chain_id = 137;

    const topPoolsIds = await getTopPools(chain_id || default_chain_id);
    const topPoolsAddresses = topPoolsIds.map((pool) => pool.id);
    const { pools: poolsData } = await getPoolData(chain_id || default_chain_id, topPoolsAddresses);

    const formattedPoolsData = poolsData.map((pool) => {
      return {
        token0: pool.token0.symbol,
        token0Address: pool.token0.id,
        token0Decimals: Number(pool.token0.decimals),
        token1: pool.token1.symbol,
        token1Address: pool.token1.id,
        token1Decimals: Number(pool.token1.decimals),
        feeTier: pool.feeTier / 10000,
        tick: Number(pool.tick),
        tvl: +Number(pool.totalValueLockedUSD).toFixed(2),
        volume: +Number(pool.volumeUSD).toFixed(2),
      };
    });

    dispatch({ type: actions.SET_UNI_TOP_POOLS, payload: { uniswap_top_pools: formattedPoolsData } });
    return formattedPoolsData;
  };

  const updateSelectedPool = async (selectedPool) => {
    const { token0Address, token0Decimals, token1Address, token1Decimals, feeTier } = selectedPool;
    const tick = await getPoolTick(
      chain_id,
      await provider.getSigner(),
      token0Address,
      token1Address,
      token0Decimals,
      token1Decimals,
      feeTier * 10000,
    );
    const pool = { ...selectedPool, tick };
    dispatch({ type: actions.SELECT_POOL, payload: { selected_pool: pool } });
  };

  return { getUniPools, updateSelectedPool };
};
