import { ethers } from 'ethers';
import { useSharedState } from '../context/store';
import { getPositionManager } from '../helpers/contracts';
import { useNotifications } from './useNotifications';
import addresses from '../contracts/addresses';
import actions from '../context/actions';

export const useModules = () => {
  const [state, dispatch] = useSharedState();
  const { account, provider, chain_id, selected_position, positions } = state;
  const { notify, notifyLoadingAsyncTx, setWalletActionRequired } = useNotifications();

  const moduleNames = {
    AaveModule: 'Aave Module',
    IdleLiquidityModule: 'Uniswap Rebalance Module',
    AutoCompoundModule: 'Auto Compound Module',
  };

  const toggleModule = async (moduleName) => {
    try {
      const signer = await provider.getSigner();
      const PositionManager = await getPositionManager(account, chain_id, signer);

      if (!selected_position) throw new Error('Please select a position first');

      const { isActive } = await PositionManager.getModuleInfo(
        selected_position.id,
        addresses[chain_id].modules[moduleName],
      );

      const gasLimitEstimate = await PositionManager.estimateGas.toggleModule(
        selected_position.id,
        addresses[chain_id].modules[moduleName],
        !isActive,
      );

      setWalletActionRequired(true);
      const tx = await PositionManager.toggleModule(
        selected_position.id,
        addresses[chain_id].modules[moduleName],
        !isActive,
        { gasLimit: gasLimitEstimate.add(150000), from: account },
      );
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, `${!isActive ? 'Activating ' : 'Deactivating'} ${moduleNames[moduleName]}`);

      // update the selected position module state
      const updatedPosition = positions.find((position) => position.id === selected_position.id);
      updatedPosition.module_states[moduleName] = !isActive;
      dispatch({ type: actions.SELECT_POSITION, payload: { selected_position: updatedPosition } });

      // update all the positions in place
      const updatedPositions = positions.map((position) => {
        if (position.id === selected_position.id) {
          position.module_states[moduleName] = !isActive;
        }
        return position;
      });
      dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: updatedPositions } });
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error while toggling module data');

      console.error(err?.message);
    }
  };

  const setModuleData = async (moduleName, data) => {
    try {
      const signer = await provider.getSigner();
      const PositionManager = await getPositionManager(account, chain_id, signer);

      const encodedData = ethers.utils.defaultAbiCoder.encode(['uint256'], [data]);

      const gasLimitEstimate = await PositionManager.estimateGas.setModuleData(
        selected_position.id,
        addresses[chain_id].modules[moduleName],
        encodedData,
      );

      setWalletActionRequired(true);
      const tx = await PositionManager.setModuleData(
        selected_position.id,
        addresses[chain_id].modules[moduleName],
        encodedData,
        { gasLimit: gasLimitEstimate.add(150000), from: account },
      );
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, `Setting ${moduleNames[moduleName]} options`);

      // update the selected position module state
      const updatedPosition = positions.find((position) => position.id === selected_position.id);
      updatedPosition.module_data[moduleName] = data;
      dispatch({ type: actions.SELECT_POSITION, payload: { selected_position: updatedPosition } });

      // update all the positions in place
      const updatedPositions = positions.map((position) => {
        if (position.id === selected_position.id) {
          position.module_data[moduleName] = data;
        }
        return position;
      });

      dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: updatedPositions } });
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error while setting module data');
      console.error(err?.message);
    }
  };

  // Orbit Modules 💎
  const toggleAutocompound = async () => await toggleModule('AutoCompoundModule');
  const toggleIdleLiquidity = async () => await toggleModule('IdleLiquidityModule');
  const toggleAave = async () => await toggleModule('AaveModule');

  return { toggleAutocompound, toggleIdleLiquidity, toggleAave, setModuleData };
};
