import { useState } from 'react';
import RegularSection from './RegularSection';
import ZapSection from './ZapSection';

export default function WithdrawBox({ empty = false }) {
  const [showRegular, setShowRegular] = useState(true);

  return (
    <div className='xs:mt-8 md:mt-0 md:ml-4'>
      <div className='deposit-box xs:ml-0 md:ml-4'>
        <div className='grid grid-cols-2 bg-primary rounded-t'>
          <div className={` rounded-t ` + (showRegular ? ' bg-primary ' : 'bg-white')}>
            <button
              className={
                `purple-btn font-medium work-sans-bold text-lg no-opacity w-full no-outline uppercase ` +
                (showRegular ? 'rounded-t primary bg-white rounded-less' : 'rounded-t-r')
              }
              onClick={() => {
                setShowRegular(true);
              }}
            >
              Regular
            </button>
          </div>
          <div className='bg-white rounded-t '>
            <button
              className={
                `purple-btn font-medium work-sans-bold text-lg w-full  no-opacity rounded-t rounded-t-l no-outline uppercase ` +
                (!showRegular ? ' primary bg-white' : '')
              }
              onClick={() => {
                setShowRegular(false);
              }}
            >
              Zap
            </button>
          </div>
        </div>
        {empty ? (
          <div className='w-full h-[510px] relative grid place-items-center'>
            <div className='text-gray-500 azeret text-xl mb-5'>
              <p>Nothing to withdraw</p>
            </div>
            <div className='absolute bottom-6 right-6 rounded-xl'>
              <button className='rounded-2xl bg-gray-400 text-white justify-center items-center h-11 font-medium azeret-semibold px-5 px-md-3 px-lg-5 uppercase'>
                Withdraw
              </button>
            </div>
          </div>
        ) : (
          <>{showRegular ? <RegularSection /> : <ZapSection />}</>
        )}
      </div>
    </div>
  );
}
