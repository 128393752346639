import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { getDepositRecipes, getERC20 } from '../helpers/contracts';

export const useZapMint = () => {
  const [{ provider, chain_id }] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const zapMint = async (token0Address, token1Address, amountDesired, tokenAddress, tickLower, tickUpper, fee) => {
    try {
      const signer = await provider.getSigner();
      const DepositRecipes = getDepositRecipes(chain_id, signer);
      const token = getERC20(tokenAddress, signer);
      const tokenDecimals = await token.decimals();
      const amount = '0x' + Math.trunc(amountDesired * 10 ** tokenDecimals).toString(16);
      const feeInBasePoints = Number(fee) * 10000;

      const gasLimitEstimate = await DepositRecipes.estimateGas.zapInUniNft(
        tokenAddress,
        amount,
        token0Address,
        token1Address,
        tickLower,
        tickUpper,
        feeInBasePoints,
      );

      setWalletActionRequired(true);
      const tx = await DepositRecipes.zapInUniNft(
        tokenAddress,
        amount,
        token0Address,
        token1Address,
        tickLower,
        tickUpper,
        feeInBasePoints,
        { gasLimit: gasLimitEstimate.add(150000) },
      );
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Zapping your position');
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during zap deposit');
      console.error(err?.message);
    }
  };

  return { zapMint };
};
