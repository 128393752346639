/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Pool, TickMath } from '@uniswap/v3-sdk';
import { Token } from '@uniswap/sdk-core';
import { useMint } from '../../../hooks/useMint';
import { useApprove } from '../../../hooks/useApprove';
import { useSharedState } from '../../../context/store';
import JSBI from 'jsbi';
import addresses from '../../../contracts/addresses';
import TokenImage from '../../utils/TokenImage';
import {
  getPriceByTick,
  getClosestAvailableTick,
  getTokenAmountsFromInput,
  maxAmount,
  getSuggestedUpperTick,
  getSuggestedLowerTick,
  checkTokenBalance,
} from '../../../utils/utils';

export default function RegularSection() {
  const [{ chain_id, provider, selected_pool }] = useSharedState();
  const { checkAllowance, approveToken } = useApprove();
  const { mintAndDeposit } = useMint();

  const [isApproved, setIsApproved] = useState(false);
  const [tickData, setTickData] = useState({});
  const [positionData, setPositionData] = useState({});
  const [suggestedRange, setSuggestedRange] = useState({});
  const [isToken0Available, setIsToken0Available] = useState(true);
  const [isToken1Available, setIsToken1Available] = useState(true);
  const [lastAmountChanged, setLastAmountChanged] = useState(null);

  // Default mount of tick spacings to use
  // for the lower range (negative delta) and upper range (positive delta)
  const tickSpacingWidth = 10;

  // Warning messages for the user
  const OUT_OF_RANGE_ERROR = "The selected prices are out of the pool's range";
  const WRONG_TICK_ORDER_ERROR = 'The selected prices are in the wrong order';

  const decimalsDiff = (pool) => {
    return pool.token0Decimals - pool.token1Decimals;
  };

  // Remove zeros from all-zero decimal number: 0.0000 -> 0
  const parseUnnecessaryZeros = (amount) => {
    if (![...amount].filter((x) => x !== '0' && x !== '.').length) return '0';
    else return amount;
  };

  // Check if the tick (string) is valid: only digits 0-9 and one "," or "."
  const checkIsValidTick = (tick) => {
    if (tick.match(/^[0-9,.]*$/) === null) return false;
    if ((tick.match(/\./g) || []).concat(tick.match(/,/g) || []).length > 1) return false;
    return true;
  };

  // Given the amount of a token of the pool, return the amount of the other one in the pool
  // "amount" is the amount of the input token
  // "zeroForOne" is a flag to indicate whether to calculate the amount from token0 to token1 or vice versa
  const getOtherTokenAmount = (amount, zeroForOne, tickLower, tickUpper) => {
    let { feeTier, tick, token0Decimals, token1Decimals } = selected_pool;

    if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(Number(tick), feeTier, tickSpacingWidth);
    if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(Number(tick), feeTier, tickSpacingWidth);

    const currentSqrt = TickMath.getSqrtRatioAtTick(Number(tick));
    const tokenDecimals = zeroForOne ? token0Decimals : token1Decimals;
    const tokenA = new Token(chain_id, selected_pool.token0Address, Number(selected_pool.token0Decimals));
    const tokenB = new Token(chain_id, selected_pool.token1Address, Number(selected_pool.token1Decimals));
    const pool = new Pool(tokenA, tokenB, Number(feeTier) * 10000, currentSqrt, JSBI.BigInt(0), Number(tick), []);
    const tokens = getTokenAmountsFromInput(amount, tokenDecimals, zeroForOne, tick, tickLower, tickUpper, pool);

    return zeroForOne
      ? (tokens.amount1 / Math.pow(10, token1Decimals)).toFixed(token1Decimals)
      : (tokens.amount0 / Math.pow(10, token0Decimals)).toFixed(token0Decimals);
  };

  const handleChangeAmount = (e, tokenNumber) => {
    var amount = e.target.value;
    if (isNaN(amount)) amount = 0;

    const { tickLower, tickUpper } = positionData;
    var otherAmount = getOtherTokenAmount(amount, !tokenNumber, tickLower, tickUpper);
    if (isNaN(otherAmount)) otherAmount = 0;

    const amountSet = `amount${tokenNumber}Desired`;
    const otherAmountSet = `amount${tokenNumber ? 0 : 1}Desired`;

    setLastAmountChanged(tokenNumber);
    setPositionData({ ...positionData, [amountSet]: amount, [otherAmountSet]: otherAmount });
  };

  // Automatically update amounts when ticks change
  const handleChangeTick = (e, tickType) => {
    const tick = e.target.value;
    const tickToChange = tickType === 'Lower' ? 'tickLower' : 'tickUpper';

    // Perform some simple validation on the input (only numbers + decimals allowed)
    if (!checkIsValidTick(tick)) return;

    // Update the tick to the new value visually
    setTickData({ ...tickData, [tickToChange]: tick });

    try {
      // Get the closest tick spacing to the new tick
      const newTick = getClosestAvailableTick(tick, selected_pool.feeTier, decimalsDiff(selected_pool));

      // Only update the amounts if we have already selected one of them
      if (lastAmountChanged !== null) {
        const amountSet = lastAmountChanged === 0 ? 'amount0Desired' : 'amount1Desired';
        const amountToChange = lastAmountChanged === 0 ? 'amount1Desired' : 'amount0Desired';

        // Get the amount of the other token in the pool based on the new tick
        var newAmount = getOtherTokenAmount(
          positionData[amountSet],
          !lastAmountChanged,
          tickType === 'Lower' ? newTick : positionData.tickLower,
          tickType === 'Upper' ? newTick : positionData.tickUpper,
        );

        // Validate the new amount (e.g. it might be negative if out of range)
        if (newAmount < 0 || isNaN(newAmount)) newAmount = 0;

        setPositionData({ ...positionData, [tickToChange]: newTick, [amountToChange]: newAmount });
      } else {
        setPositionData({ ...positionData, [tickToChange]: newTick });
      }
    } catch (err) {
      console.warn(err?.message);
      setPositionData({ ...positionData, [tickToChange]: '0' });
    }
  };

  const selectMaxAmount = async (tokenAddress, tokenNumber) => {
    var max = await maxAmount(await provider.getSigner(), tokenAddress);
    var maxOtherToken = getOtherTokenAmount(max, !tokenNumber, positionData.tickLower, positionData.tickUpper);
    if (maxOtherToken < 0 || isNaN(maxOtherToken)) maxOtherToken = 0;

    setLastAmountChanged(tokenNumber);

    max = Number(max).toFixed(selected_pool[`token${tokenNumber}Decimals`]);
    maxOtherToken = Number(maxOtherToken).toFixed(selected_pool[`token${tokenNumber ? 0 : 1}Decimals`]);

    setPositionData({
      ...positionData,
      [`amount${tokenNumber}Desired`]: parseUnnecessaryZeros(max),
      [`amount${tokenNumber ? 0 : 1}Desired`]: parseUnnecessaryZeros(maxOtherToken),
    });
  };

  const mint = async () => {
    try {
      let { tickLower, tickUpper, amount0Desired, amount1Desired } = positionData;
      let { token0Address, token1Address, feeTier, tick } = selected_pool;

      if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(tick, feeTier, tickSpacingWidth);
      if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(tick, feeTier, tickSpacingWidth);

      await mintAndDeposit(token0Address, token1Address, amount0Desired, amount1Desired, tickLower, tickUpper, feeTier);
    } catch (err) {
      console.error(err?.message);
    }
  };

  // Change ticks to the selected range, and update the amounts accordingly
  // "shouldResetAmounts" is a boolean indicating whether to set the token amounts to 0 or scale
  // them to the selected range and is used to avoid amounts update errors while changing selected_pool
  const selectSuggestedRange = (shouldResetAmounts = true) => {
    const tickLowerSuggested = getSuggestedLowerTick(selected_pool.tick, selected_pool.feeTier, tickSpacingWidth);
    const tickUpperSuggested = getSuggestedUpperTick(selected_pool.tick, selected_pool.feeTier, tickSpacingWidth);
    const priceLowerSuggested = getPriceByTick(tickLowerSuggested, true, decimalsDiff(selected_pool)).toPrecision(7);
    const priceUpperSuggested = getPriceByTick(tickUpperSuggested, true, decimalsDiff(selected_pool)).toPrecision(7);
    const updatedPositionData = { ...positionData, tickLower: tickLowerSuggested, tickUpper: tickUpperSuggested };

    // Also update suggested token amounts, only if this function is called by the user,
    // and not when changing the selected pool or doing automatic updates
    if (shouldResetAmounts) {
      var amount0Suggested = getOtherTokenAmount(
        positionData.amount1Desired,
        false,
        tickLowerSuggested,
        tickUpperSuggested,
      );
      var amount1Suggested = getOtherTokenAmount(
        positionData.amount0Desired,
        true,
        tickLowerSuggested,
        tickUpperSuggested,
      );

      updatedPositionData.amount0Desired = parseUnnecessaryZeros(amount0Suggested);
      updatedPositionData.amount1Desired = parseUnnecessaryZeros(amount1Suggested);
    } else {
      updatedPositionData.amount0Desired = 0;
      updatedPositionData.amount1Desired = 0;
    }

    setSuggestedRange({ tickLower: priceLowerSuggested, tickUpper: priceUpperSuggested });
    setTickData({ tickLower: priceLowerSuggested, tickUpper: priceUpperSuggested });
    setPositionData(updatedPositionData);
  };

  // Check if the user balance is enough for the amount desired
  const checkAvailableBalances = async () => {
    if (positionData?.amount0Desired !== null && !isNaN(positionData?.amount0Desired)) {
      try {
        setIsToken0Available(
          await checkTokenBalance(positionData.amount0Desired, selected_pool.token0Address, await provider.getSigner()),
        );
      } catch (err) {
        console.warn(err?.message);
        setIsToken0Available(false);
      }
    }
    if (positionData?.amount1Desired !== null && !isNaN(positionData?.amount1Desired)) {
      try {
        setIsToken1Available(
          await checkTokenBalance(positionData.amount1Desired, selected_pool.token1Address, await provider.getSigner()),
        );
      } catch (err) {
        console.warn(err?.message);
        setIsToken1Available(false);
      }
    }
  };

  // Check that the DepositRecipes contract is approved as token spender
  const checkIsApproved = async () => {
    try {
      const _p1 = checkAllowance(selected_pool.token0Address, addresses[chain_id].DepositRecipes);
      const _p2 = checkAllowance(selected_pool.token1Address, addresses[chain_id].DepositRecipes);
      const [approved0, approved1] = await Promise.all([_p1, _p2]);
      setIsApproved(approved0 && approved1 ? true : false);
    } catch (err) {
      console.error(err?.message);
    }
  };

  const approveTokens = async () => {
    try {
      const _p1 = approveToken(selected_pool?.token0Address, addresses[chain_id].DepositRecipes);
      const _p2 = approveToken(selected_pool?.token1Address, addresses[chain_id].DepositRecipes);
      await Promise.all([_p1, _p2]);
      setIsApproved(true);
    } catch (err) {
      console.error(err?.message);
    }
  };

  const currentPrice = useMemo(() => {
    if (selected_pool)
      return getPriceByTick(Number(selected_pool.tick), true, decimalsDiff(selected_pool)).toPrecision(5);
  }, [selected_pool?.tick]);

  const outOfRangeWarnings = useMemo(() => {
    if (selected_pool && positionData) {
      if (selected_pool.tick < positionData.tickLower || selected_pool.tick > positionData.tickUpper) {
        if (positionData.tickLower > positionData.tickUpper) {
          return WRONG_TICK_ORDER_ERROR;
        } else return OUT_OF_RANGE_ERROR;
      }
    }
    return false;
  }, [selected_pool?.tick, positionData?.tickLower, positionData?.tickUpper]);

  useEffect(() => {
    setLastAmountChanged(null);
    setPositionData({ ...positionData, amount0Desired: '0', amount1Desired: '0' });
    if (selected_pool?.tick && selected_pool?.feeTier) selectSuggestedRange(false);
    if (selected_pool?.token0Address && selected_pool?.token1Address) checkIsApproved();
  }, [selected_pool]);

  useEffect(() => {
    if (provider && positionData && selected_pool) checkAvailableBalances();
  }, [provider, positionData?.amount0Desired, positionData?.amount1Desired, selected_pool]);

  return (
    <>
      <div className='py-2 px-4 mt-4'>
        <div className='text-center md:text-left background-light-gray rounded-xl'>
          <h4 className='text-black text-lg text-center font-bold work-sans-bold primary pt-3 pb-1 uppercase'>
            Set price range
          </h4>
          {selected_pool ? (
            <p className='text-gray text-base text-center azeret pb-2'>
              Current Price:<br></br>{' '}
              <span className='primary font-bold'>
                {currentPrice}
                &nbsp;
                {selected_pool?.token1?.toUpperCase()}
              </span>{' '}
              per
              <span className='primary font-bold'> {selected_pool?.token0?.toUpperCase()}</span>
            </p>
          ) : (
            <div className='py-[26px]' />
          )}
        </div>
        <div className='flex items-bottom justify-between background-light-gray relative rounded-xl mt-3 border-primary border-2 pt-10 pb-1 px-3 w-full'>
          <button
            className={`absolute top-2 right-2 uppercase text-sm azeret text-white bg-gray-400 rounded-[9px] px-2 py-[2px]  ${
              tickData?.tickLower === suggestedRange?.tickLower ? 'bg-gray-400' : 'bg-second primary'
            }`}
            onClick={() => selectSuggestedRange()}
          >
            Suggested range
          </button>
          <p className='text-xl azeret-md primary uppercase'>Lower</p>
          <input
            type='text'
            value={tickData?.tickLower || ''}
            onChange={(e) => handleChangeTick(e, 'Lower')}
            className='text-gray text-xl text-right azeret hidden-input w-52'
          />
        </div>
        <div className='flex items-bottom justify-between background-light-gray relative rounded-xl mt-3 border-primary border-2 pt-10 pb-1 px-3 w-full'>
          <button
            className={`absolute top-2 right-2 uppercase text-sm azeret text-white rounded-[9px] px-2 py-[1px] ${
              tickData?.tickUpper === suggestedRange?.tickUpper ? 'bg-gray-400' : 'bg-second primary'
            }`}
            onClick={() => selectSuggestedRange()}
          >
            Suggested range
          </button>
          <p className='text-xl azeret-md primary uppercase'>Upper</p>
          <input
            type='text'
            value={tickData?.tickUpper || ''}
            onChange={(e) => handleChangeTick(e, 'Upper')}
            className='text-gray text-xl text-right azeret hidden-input w-52'
          />
        </div>
        <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
          <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
            Deposit amounts
          </h4>
        </div>
        <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
          <div className='flex col-span-2 items-center'>
            <TokenImage token={selected_pool?.token0?.toLowerCase()} offsetMarginLeft='0' offsetSize='40px' />
            <p className='text-lg azeret-md text-gray self-center ml-2'>{selected_pool?.token0?.toUpperCase()}</p>
          </div>
          <div
            className={`text-center md:text-left background-light-gray rounded-xl 
              ${
                isToken0Available ? 'border-primary' : 'border-red-600'
              } border-2 grid grid-cols-1 mx-auto py-2 px-3 col-span-3`}
          >
            <button
              className={`text-sm btn-main px-2 py-0 mr-0 ml-auto  rounded-xl mb-2 opacity pointer uppercase ${
                !selected_pool ? 'bg-gray-400 text-white' : ''
              }`}
              onClick={() => selectMaxAmount(selected_pool?.token0Address, 0)}
            >
              max
            </button>
            <input
              type='text'
              value={positionData?.amount0Desired?.toString() || '0'}
              onChange={(e) => handleChangeAmount(e, 0)}
              placeholder='0'
              className='text-gray text-xl text-right azeret hidden-input'
            />
          </div>
        </div>
        <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
          <div className='flex col-span-2 items-center'>
            <TokenImage token={selected_pool?.token1?.toLowerCase()} offsetMarginLeft='0' offsetSize='40px' />

            <p className='text-lg azeret-md text-gray self-center ml-2'>{selected_pool?.token1?.toUpperCase()}</p>
          </div>
          <div
            className={`text-center md:text-left background-light-gray rounded-xl 
          ${
            isToken1Available ? 'border-primary' : 'border-red-600'
          } border-2 grid grid-cols-1 mx-auto py-2 px-3 col-span-3`}
          >
            <button
              className={`text-sm btn-main px-2 py-0 mr-0 ml-auto  rounded-xl mb-2 opacity pointer uppercase ${
                !selected_pool ? 'bg-gray-400 text-white' : ''
              }`}
              onClick={() => selectMaxAmount(selected_pool?.token1Address, 1)}
            >
              max
            </button>
            <input
              type='text'
              value={positionData?.amount1Desired?.toString() || '0'}
              onChange={(e) => handleChangeAmount(e, 1)}
              placeholder='0'
              className='text-gray text-xl text-right azeret hidden-input'
            />
          </div>
        </div>
        <div className='row mx-auto'>
          {isApproved ? (
            <button
              disabled={isToken0Available && isToken1Available ? false : true}
              className={`rounded-xl py-[6px] px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto font-medium azeret uppercase ${
                !selected_pool || !(isToken0Available && isToken1Available)
                  ? 'bg-gray-400 text-white'
                  : 'bg-second primary'
              }`}
              onClick={mint}
            >
              {isToken0Available && isToken1Available
                ? 'Deposit'
                : !isToken0Available && isToken1Available
                ? `Not enough ${selected_pool?.token0}`
                : isToken0Available && !isToken1Available
                ? `Not enough ${selected_pool?.token1}`
                : !isToken0Available && !isToken1Available && `Not enough tokens`}
            </button>
          ) : (
            <button
              className={`rounded-2xl btn-main px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto uppercase ${
                !selected_pool ? 'bg-gray-400 text-white' : 'primary'
              }`}
              onClick={approveTokens}
            >
              approve
            </button>
          )}
        </div>
      </div>
    </>
  );
}
