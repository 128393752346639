import { BigNumber, ethers } from 'ethers';
import { getPositionManager, getNonFungiblePositionManager, getERC20 } from '../helpers/contracts';
import { getPriceByTick, UniNftExplorerUrl } from '../utils/utils';
import { getPositionMetrics } from '../api/getPositionMetrics';
import { useSharedState } from '../context/store';
import { useTokenValue } from './useTokenValue';
import addresses from '../contracts/addresses';
import actions from '../context/actions';

const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);

export const usePositions = () => {
  const [{ account, provider, chain_id }, dispatch] = useSharedState();
  const { getValueOfLiquidity, getTokenValue } = useTokenValue();

  const getAllPositions = async () => {
    try {
      dispatch({ type: actions.LOADING_STATE, payload: { loading_state: 'positions' } });
      dispatch({ type: actions.UPDATE_POSITIONS, payload: [] });

      const signer = await provider.getSigner();
      const NonfungiblePositionManager = getNonFungiblePositionManager(chain_id, signer);
      const NonfungiblePositionManagerWithoutSigner = getNonFungiblePositionManager(chain_id, provider);
      const PositionManager = await getPositionManager(account, chain_id, signer);

      if (!PositionManager) {
        dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: [] } });
        dispatch({ type: actions.LOADING_STATE, payload: { loading_state: false } });
        return [];
      }

      const positions = [];
      const positionsBalance = (await NonfungiblePositionManager.balanceOf(PositionManager.address)).toNumber();

      for (let i = 0; i < positionsBalance; i++) {
        try {
          const id = (await NonfungiblePositionManager.tokenOfOwnerByIndex(PositionManager.address, i)).toNumber();

          const position = await NonfungiblePositionManager.positions(id);

          let token0 = getERC20(position.token0, signer);
          let token1 = getERC20(position.token1, signer);
          let token0Decimals = await token0.decimals();
          let token1Decimals = await token1.decimals();
          let token0Name = (await token0.symbol()).toLowerCase();
          let token1Name = (await token1.symbol()).toLowerCase();

          const moduleState = {}; // true/false if the module is enabled or not
          const moduleData = {}; // number representing the fee threshold or distance from range

          for (const moduleName of Object.keys(addresses[chain_id].modules)) {
            const { isActive, data } = await PositionManager.getModuleInfo(id, addresses[chain_id].modules[moduleName]);

            moduleState[moduleName] = isActive;
            moduleData[moduleName] = await ethers.utils.defaultAbiCoder.decode([`uint256`], data)[0].toNumber();
          }

          const explorerLink = `${UniNftExplorerUrl}${Number(id)}?chain=${addresses[chain_id].ChainName}`;

          const diffDecimals = token0Decimals - token1Decimals;
          const lowerRange = `${getPriceByTick(position.tickLower, true, diffDecimals).toPrecision(3)}`;
          const upperRange = `${getPriceByTick(position.tickUpper, true, diffDecimals).toPrecision(3)}`;
          const positionRange = `${lowerRange}-${upperRange}`;

          const isOnAave = Number(position.liquidity) === 0;

          positions.push({
            id: Number(id),
            liquidity: Number(position.liquidity),
            value: null,
            isOnAave: isOnAave,
            module_states: moduleState,
            module_data: moduleData,
            range: positionRange,
            token0: token0Name,
            token1: token1Name,
            token0Address: position.token0,
            token1Address: position.token1,
            tickLower: Number(position.tickLower),
            tickUpper: Number(position.tickUpper),
            token0Decimals: token0Decimals,
            token1Decimals: token1Decimals,
            fee: Number(position.fee) / 10000,
            link: explorerLink,
          });

          dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions } });
        } catch (e) {
          continue;
        }
      }

      for (const id in positions) {
        if (positions[id].isOnAave) {
          const data = await getPositionMetrics(chain_id, positions[id].id);
          positions[id].value = data.position.marketValueUSD;
          dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions } });
          continue;
        }

        const {
          token0,
          token1,
          token0Decimals,
          token1Decimals,
          token0Address,
          token1Address,
          fee,
          liquidity,
          tickLower,
          tickUpper,
        } = positions[id];

        const dollarValue = await getValueOfLiquidity({
          token0,
          token1,
          token0Decimals,
          token1Decimals,
          token0Address,
          token1Address,
          fee: fee * 10000,
          liquidity,
          tickLower,
          tickUpper,
        });

        const uncollectedFees = await NonfungiblePositionManagerWithoutSigner.callStatic.collect(
          {
            tokenId: positions[id].id,
            recipient: PositionManager.address,
            amount0Max: MAX_UINT128,
            amount1Max: MAX_UINT128,
          },
          { from: PositionManager.address },
        );

        var uncollectedFeeToken0Value = await getTokenValue(
          { address: token0Address, decimals: token0Decimals },
          uncollectedFees.amount0,
        );

        var uncollectedFeeToken1Value = await getTokenValue(
          { address: token1Address, decimals: token1Decimals },
          uncollectedFees.amount1,
        );

        if (uncollectedFeeToken0Value === null) uncollectedFeeToken0Value = 0;
        if (uncollectedFeeToken1Value === null) uncollectedFeeToken1Value = 0;

        positions[id].uncollectedFee = uncollectedFeeToken0Value + uncollectedFeeToken1Value;
        positions[id].value = dollarValue;
        dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions } });
      }

      // Final dispatch is needed if we don't have any positions by the end of the loop
      positions?.length === 0 && dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: [] } });

      dispatch({ type: actions.LOADING_STATE, payload: { loading_state: false } });
    } catch (err) {
      console.error(err?.message);
      return [];
    }
  };

  return { getAllPositions };
};
