/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { getDollarFormat, getPoolTick } from '../../../utils/utils';
import { useSharedState } from '../../../context/store';
import actions from '../../../context/actions';
import CustomTooltip from '../../utils/CustomTooltip';

import AddSvg from '../../icons/AddSvg';
import CloseSvg from '../../icons/CloseSvg';
import LinkSvg from '../../icons/LinkSvg';
import AaveLogoBtn from '../../icons/AaveLogoBtn';
import OutOfRangeIcon from '../../icons/OutOfRangeIcon';
import PoolImage from '../../utils/PoolImage';

export default function PositionCard(props) {
  const { position, page, selected, onClick } = props;
  const [{ chain_id, provider, vault_positions, uniswap_user_positions }, dispatch] = useSharedState();
  const [isOutOfRange, setIsOutOfRange] = useState(false);

  const addToSmartVault = async () => {
    dispatch({ type: actions.SET_VAULT_POSITIONS, payload: { vault_positions: [...vault_positions, position] } });

    const updated_uniswap_user_positions = uniswap_user_positions.filter((pos) => pos.tokenId !== position.tokenId);
    dispatch({
      type: actions.SET_UNI_USER_POSITIONS,
      payload: { uniswap_user_positions: updated_uniswap_user_positions },
    });
  };

  const removeFromSmartVault = async () => {
    const updated_vault_positions = vault_positions.filter((pos) => pos.tokenId !== position.tokenId);
    dispatch({ type: actions.SET_VAULT_POSITIONS, payload: { vault_positions: updated_vault_positions } });

    dispatch({
      type: actions.SET_UNI_USER_POSITIONS,
      payload: { uniswap_user_positions: [...uniswap_user_positions, position] },
    });
  };

  const isAaveModuleActive = position?.module_states?.AaveModule;
  const isCurrentlyOnAave = isAaveModuleActive && position?.isOnAave;

  useEffect(() => {
    var isMounted = true;
    const OutOfRange = async () => {
      if (position) {
        try {
          const signer = await provider.getSigner();
          const tick = await getPoolTick(
            chain_id,
            signer,
            position.token0Address,
            position.token1Address,
            position.token0Decimals,
            position.token1Decimals,
            position.fee * 10000,
          );

          if (!isMounted) return;
          if (tick > position.tickUpper || tick < position.tickLower) setIsOutOfRange(true);
          else setIsOutOfRange(false);
        } catch (err) {
          console.error(err?.message);
          setIsOutOfRange(false);
        }
      } else setIsOutOfRange(false);
    };

    OutOfRange();

    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position?.id]);

  return (
    <>
      {position && (
        <>
          {page !== 'vault' ? (
            <div
              onClick={onClick}
              className={`relative bg-white rounded-xl ${!selected ? 'py-[12px] px-[6px]' : ''} ${
                !isCurrentlyOnAave && selected ? 'selected py-[12px] px-[6px]' : ''
              } ${isCurrentlyOnAave && selected ? 'bg-gradient-to-tr p-[3px] from-[#0bcae3] to-[#e424c8]' : ''}`}
            >
              {isCurrentlyOnAave && selected && (
                <div className='absolute inset-0 bg-gradient-to-tr from-[#0bcae3] to-[#e424c8] rounded-xl blur-[5px] animate-pulse' />
              )}
              <div
                className={`relative h-full w-full bg-white 
                ${isCurrentlyOnAave && selected ? 'rounded-xl px-[3px] py-[9px]' : ''}`}
              >
                <div className='flex gap-0 h-full'>
                  <div className={`text-center border-secondary-right pt-[6px] pr-[6px]`}>
                    <PoolImage
                      token0={position.token0.toLowerCase()}
                      token1={position.token1.toLowerCase()}
                      size='60px'
                      offsetMarginLeft='-20px'
                      className='mx-auto'
                    />
                    <p className='text-gray azeret-bold text-lg pt-[12px] leading-6 w-32'>
                      {`${position.token0.toUpperCase()}-${position.token1.toUpperCase()} ${position.fee}%`}
                    </p>
                  </div>
                  <div className='pl-[16px] relative text-left w-full'>
                    <p className='azeret-bold text-md primary leading-4 uppercase'>Liquidity</p>
                    <p className='text-gray azeret text-md pb-2'>{getDollarFormat(position.value)}</p>
                    {page !== 'import' && (
                      <>
                        <p className='azeret-bold  text-md primary leading-4 uppercase'>fee</p>
                        <p className='text-gray azeret text-md pb-2'>
                          {position.uncollectedFee ? getDollarFormat(position.uncollectedFee) : '–'}
                        </p>
                      </>
                    )}
                    <p className='azeret-bold text-md primary leading-4 uppercase'>Range</p>
                    <p className='text-gray azeret text-md'>{position.range}</p>
                    {page === 'import' ? (
                      <button
                        onClick={() => addToSmartVault()}
                        className='absolute top-0 right-0 mr-1 rounded-full p-2 btn-secondary hover:opacity-80 no-outline'
                        href='#0'
                      >
                        <AddSvg width='20px' height='20px' className='fill-btn' />
                      </button>
                    ) : (
                      <div className='absolute top-0 right-0 -mb-3 mr-1 flex flex-col gap-1'>
                        <a className='rounded-full px-2 py-2 float-right btn-secondary' href={position.link}>
                          <LinkSvg width='20px' height='20px' className='stroke-btn' />
                        </a>
                        {isCurrentlyOnAave && (
                          <div>
                            <a data-tip data-for='AaveLogoBtn' href='#' className='cursor-default'>
                              <AaveLogoBtn />
                            </a>
                            <CustomTooltip id='AaveLogoBtn'>
                              <div className='opacity-100 rounded-2xl z-10'>
                                <div className='inner-hint border-primary border-2'>
                                  <p className='primary text-sm'>
                                    The Uniswap pool tick is out of your selected range. This position is currently
                                    earning interest on Aave.
                                  </p>
                                </div>
                              </div>
                            </CustomTooltip>
                          </div>
                        )}
                        {isOutOfRange && !isCurrentlyOnAave && (
                          <div>
                            <a data-tip data-for='OutOfRangeIcon' href='#' className='cursor-default'>
                              <OutOfRangeIcon />
                            </a>
                            <CustomTooltip id='OutOfRangeIcon'>
                              <div className='opacity-100 rounded-2xl z-10'>
                                <div className='inner-hint border-primary border-2'>
                                  <p className='primary text-sm'>
                                    The Uniswap pool tick is out of your selected range. This position is not earning
                                    any fees.
                                  </p>
                                </div>
                              </div>
                            </CustomTooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              id='mini-card'
              onClick={onClick}
              className={`bg-white rounded-xl px-[6px] py-[12px] ${selected ? 'selected' : ''}`}
            >
              <div className='flex h-full'>
                <div className='text-center border-secondary-right pt-[6px] pr-[3px]'>
                  <PoolImage
                    token0={position.token0.toLowerCase()}
                    token1={position.token1.toLowerCase()}
                    size='45px'
                    offsetMarginLeft='-15px'
                    className='mx-auto'
                  />
                  <p className='text-gray azeret-bold text-base pt-[12px] w-32 px-2'>
                    {`${position.token0.toUpperCase()}-${position.token1.toUpperCase()} ${position.fee}%`}
                  </p>
                </div>
                <div className='w-full ml-[10px] relative text-left'>
                  <p className='azeret-bold text-sm primary leading-3'>LIQUIDITY</p>
                  <p className='text-gray azeret text-sm pb-2'>{getDollarFormat(position.value)}</p>
                  <p className='azeret-bold  text-sm primary leading-3'>RANGE</p>
                  <p className='text-gray azeret text-sm'>{position.range}</p>
                  <button
                    onClick={removeFromSmartVault}
                    className='absolute top-0 right-0 mr-1 rounded-full btn-secondary hover:opacity-80 no-outline'
                    href='#0'
                  >
                    <CloseSvg width='30' height='30' className='fill-btn' />
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
