import actions from './actions';
import Config from '../Config';

export const initialState = {
  is_connected: false,
  loading_state: 'initial',
  account: '',
  provider: null,
  network_name: null,
  chain_id: null,
  positions: null,
  selected_position: null,
  uniswap_top_pools: null,
  selected_pool: null,
  vault_positions: [],
  uniswap_user_positions: null,
  analytics: null,
  notifications: [],
};

export const reducer = (state, action) => {
  Config.debug && console.warn('[reducer]', action.type);

  switch (action.type) {
    case actions.LOGIN_WALLET:
      window.localStorage.setItem('shouldConnectMetamask', 'true');
      return {
        ...state,
        is_connected: true,
        account: action.payload.account,
        provider: action.payload.provider,
        network_name: action.payload.network_name,
        chain_id: action.payload.chain_id,
      };

    case actions.LOGOUT_WALLET:
      window.localStorage.removeItem('shouldConnectMetamask');
      return {
        ...state,
        is_connected: false,
        account: '',
        provider: null,
        network_name: null,
        chain_id: null,
      };

    case actions.LOADING_STATE:
      return {
        ...state,
        loading_state: action.payload.loading_state,
      };

    case actions.UPDATE_POSITIONS:
      return {
        ...state,
        positions: action.payload.positions,
      };

    case actions.SELECT_POSITION:
      return {
        ...state,
        selected_position: action.payload.selected_position,
      };

    case actions.TOGGLE_MODULE:
      return {
        ...state,
        positions: action.payload.positions,
        selected_position: action.payload.selected_position,
      };

    case actions.SET_UNI_TOP_POOLS:
      return {
        ...state,
        uniswap_top_pools: action.payload.uniswap_top_pools,
      };

    case actions.SELECT_POOL:
      return {
        ...state,
        selected_pool: action.payload.selected_pool,
      };

    case actions.SET_VAULT_POSITIONS:
      return {
        ...state,
        vault_positions: action.payload.vault_positions,
      };

    case actions.SET_UNI_USER_POSITIONS:
      return {
        ...state,
        uniswap_user_positions: action.payload.uniswap_user_positions,
      };

    case actions.SET_ANALYTICS:
      return {
        ...state,
        analytics: action.payload.analytics,
      };

    case actions.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload.notification],
      };

    case actions.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== action.payload.id),
      };

    default:
      return state;
  }
};
