const actions = {
  LOGIN_WALLET: 'LOGIN_WALLET',
  LOGOUT_WALLET: 'LOGOUT_WALLET',
  LOADING_STATE: 'LOADING_STATE',
  UPDATE_POSITIONS: 'UPDATE_POSITIONS',
  SELECT_POSITION: 'SELECT_POSITION',
  SET_UNI_TOP_POOLS: 'SET_UNI_TOP_POOLS',
  SELECT_POOL: 'SELECT_POOL',
  TOGGLE_MODULE: 'TOGGLE_MODULE',
  SET_VAULT_POSITIONS: 'SET_VAULT_POSITIONS',
  SET_UNI_USER_POSITIONS: 'SET_UNI_USER_POSITIONS',
  SET_ANALYTICS: 'SET_ANALYTICS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};

export default actions;
