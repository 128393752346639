import toast from 'react-hot-toast';
import actions from '../context/actions';
import { useSharedState } from '../context/store';
import networks from '../helpers/networks';

export const useLoading = () => {
  const [{ chain_id }, dispatch] = useSharedState();

  const loadingNotification = (promise, message) =>
    toast.promise(
      promise,
      {
        loading: message,
        success: (data) => <div>Success! {ViewTxLink(data)}</div>,
        error: (data) => <div>Error! {ViewTxLink(data)}</div>,
      },
      {
        style: {
          minWidth: '250px',
        },
        success: {
          duration: 8000,
          icon: '🔥',
        },
        error: {
          duration: 8000,
          icon: '❌',
        },
      },
    );

  const ViewTxLink = (data) => {
    try {
      const { transactionHash } = data;
      const url = networks[chain_id].blockExplorerUrls[0] + 'tx/' + transactionHash;
      return (
        <a href={url} target='_blank' rel='noopener noreferrer' className='text-blue-600 text-xs'>
          View on Block Explorer
        </a>
      );
    } catch (err) {
      console.error(err?.message);
    }
  };

  const defaultMessage = 'Loading...';

  const loadAsyncTx = async (tx, message) => {
    loadingNotification(tx.wait(), message || defaultMessage);
    try {
      await tx.wait();

      return true;
    } catch (err) {
      console.error(err?.message);

      return false;
    }
  };

  const setWalletActionRequired = (loading_state) => {
    dispatch({ type: actions.LOADING_STATE, payload: { loading_state } });
  };

  return { setWalletActionRequired, loadAsyncTx };
};
