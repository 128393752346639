import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { getWithdrawRecipes } from '../helpers/contracts';
import actions from '../context/actions';

export const useZapWithdraw = () => {
  const [{ provider, chain_id, account }, dispatch] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const withdraw = async (ids, tokenAddressOut) => {
    try {
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);

      const gasLimitEstimate = await WithdrawRecipes.estimateGas.zapOutUniNft(ids, tokenAddressOut);

      setWalletActionRequired(true);
      const tx = await WithdrawRecipes.zapOutUniNft(ids, tokenAddressOut, {
        gasLimit: gasLimitEstimate.add(150000),
        from: account,
      });
      setWalletActionRequired(false);

      const success = await notifyLoadingAsyncTx(tx, 'Withdrawing your position');

      if (success) {
        dispatch({ type: actions.SET_VAULT_POSITIONS, payload: { vault_positions: [] } });
      }
    } catch (err) {
      notify('error', 'Error during withdraw');
      setWalletActionRequired(false);
      console.error(err?.message);
    }
  };

  return { withdraw };
};
