import { useSharedState } from '../context/store';
import actions from '../context/actions';
import { useNotifications } from './useNotifications';
import { getDepositRecipes, getNonFungiblePositionManager } from '../helpers/contracts';

export const useDeposit = () => {
  const [{ provider, chain_id, account }, dispatch] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const deposit = async (ids) => {
    try {
      const signer = await provider.getSigner();
      const NonFungiblePositionManager = getNonFungiblePositionManager(chain_id, signer);
      const DepositRecipes = getDepositRecipes(chain_id, signer);

      if (!(await NonFungiblePositionManager.isApprovedForAll(account, DepositRecipes.address)))
        await NonFungiblePositionManager.setApprovalForAll(DepositRecipes.address, true);

      const gasLimitEstimate = await DepositRecipes.estimateGas.depositUniNft(ids);

      setWalletActionRequired(true);
      const tx = await DepositRecipes.depositUniNft(ids, {
        gasLimit: gasLimitEstimate.add(150000),
        from: account,
      });
      setWalletActionRequired(false);

      const success = await notifyLoadingAsyncTx(tx, 'Depositing your positions');

      if (success) {
        dispatch({ type: actions.SET_VAULT_POSITIONS, payload: { vault_positions: [] } });
      }
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during deposit');

      console.error(err?.message);
    }
  };

  return { deposit };
};
