import { Contract } from 'ethers';
import { Token, MaxUint256 } from '@uniswap/sdk-core';
import JSBI from 'jsbi';
import numbro from 'numbro';
import IUniswapV3PoolStateABI from '@uniswap/v3-core/artifacts/contracts/interfaces/pool/IUniswapV3PoolState.sol/IUniswapV3PoolState.json';
import { Interface } from 'ethers/lib/utils';
import { getERC20 } from '../helpers/contracts';
import {
  TickMath,
  tickToPrice,
  computePoolAddress,
  nearestUsableTick,
  Position,
  maxLiquidityForAmounts,
} from '@uniswap/v3-sdk';

export const UniNftExplorerUrl = 'https://app.uniswap.org/#/pool/';

export const maxAmount = async (signer, tokenAddress) => {
  const token = getERC20(tokenAddress, signer);
  const decimals = await token.decimals();
  const maxWithDecimals = await token.balanceOf(await signer.getAddress());

  return Number(maxWithDecimals / Math.pow(10, decimals));
};

export const checkTokenBalance = async (desiredAmount, tokenAddress, signer) => {
  const token = getERC20(tokenAddress, signer);
  const decimals = await token.decimals();
  const balance = await token.balanceOf(await signer.getAddress());

  return Number(balance / Math.pow(10, decimals)) >= desiredAmount;
};

export const shortenAddress = (address) => address.slice(0, 4) + '...' + address.slice(-4);

const getToken = ({ sortOrder, decimals = 18, chainId = 1 }) => {
  return new Token(
    chainId,
    `0x${Array(40).fill(`${sortOrder}`).join('')}`,
    decimals,
    `T${sortOrder}`,
    `token${sortOrder}`,
  );
};

export const getPriceByTick = (tick, token0Ontoken1, diffDecimals) => {
  const token0 = getToken({ sortOrder: token0Ontoken1 ? 0 : 1 });
  const token1 = getToken({ sortOrder: token0Ontoken1 ? 1 : 0 });

  if (!Number.isInteger(Math.round(Number(tick)))) throw new Error('tick is not an int');
  const price = tickToPrice(token0, token1, Math.round(Number(tick)));
  const priceAsNumber = Number(price.toSignificant(18));

  return priceAsNumber * Math.pow(10, diffDecimals);
};

export const getClosestAvailableTick = (tick, feeTier, decimalsAdjustment) => {
  const tickSpacing = feeTier * 200;
  const roundedTick =
    2 * Math.trunc(Math.log(Math.sqrt(Number(tick * Math.pow(10, -decimalsAdjustment)))) / Math.log(1.0001));

  return nearestUsableTick(roundedTick, tickSpacing);
};

export const getSuggestedUpperTick = (tick, feeTier, numberOfSpacing) => {
  return Number(tick) - (Number(tick) % (feeTier * 200)) + feeTier * 200 * (numberOfSpacing + 1);
};

export const getSuggestedLowerTick = (tick, feeTier, numberOfSpacing) => {
  return Number(tick) - (Number(tick) % (feeTier * 200)) - feeTier * 200 * numberOfSpacing;
};

export const getDollarFormat = (num, digits = 2, round = true) => {
  if (num === null || num === undefined) return '–';
  if (num === 0) return '$0.00';
  if (num < 0.001 && digits <= 3) return '<$0.001';
  if (num > 1000 && num < 10000) return `$${Number(num).toFixed(2)}`;
  if (num < 1 && num > 0.01) return `$${Number(num).toFixed(digits)}`;

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num >= 10_000 ? digits : 2,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  });
};

export const getTokenAmountsFromInput = (tokenAmount, tokenDecimals, zeroForOne, tick, tickLower, tickUpper, pool) => {
  let liquidity;

  const sqrtRatioX96 = TickMath.getSqrtRatioAtTick(Number(tick));
  const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(tickLower));
  const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(tickUpper));
  const amountWithDecimals = JSBI.BigInt(Math.round(Number(tokenAmount) * Math.pow(10, tokenDecimals)));

  if (zeroForOne) {
    liquidity = maxLiquidityForAmounts(
      sqrtRatioX96,
      sqrtRatioAX96,
      sqrtRatioBX96,
      amountWithDecimals,
      MaxUint256,
      true,
    );
  } else {
    liquidity = maxLiquidityForAmounts(
      sqrtRatioX96,
      sqrtRatioAX96,
      sqrtRatioBX96,
      MaxUint256,
      amountWithDecimals,
      true,
    );
  }

  const position = new Position({
    pool: pool,
    tickLower: Number(tickLower),
    tickUpper: Number(tickUpper),
    liquidity: liquidity,
  });

  return { amount0: Number(position.amount0.quotient), amount1: Number(position.amount1.quotient) };
};

const getLiquidityForAmount0 = (amount, tickLower, tickUpper) => {
  const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(tickLower));
  const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(tickUpper));
  const Q96 = Math.pow(2, 96);

  return (amount * sqrtRatioAX96 * sqrtRatioBX96) / Q96 / (sqrtRatioBX96 - sqrtRatioAX96);
};

const getLiquidityForAmount1 = (amount, tickLower, tickUpper) => {
  const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(tickLower));
  const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(tickUpper));
  const Q96 = Math.pow(2, 96);

  return (amount * Q96) / (sqrtRatioBX96 - sqrtRatioAX96);
};

export const getLiquidityForAmounts = async (amount0, amount1, tickLower, tickUpper, tick) => {
  if (tick <= tickLower) return getLiquidityForAmount0(amount0, tickLower, tickUpper);
  else if (tick >= tickUpper) return getLiquidityForAmount1(amount1, tickLower, tickUpper);
  else
    return Math.min(getLiquidityForAmount0(amount0, tickLower, tick), getLiquidityForAmount1(amount1, tick, tickUpper));
};

const getAmount0ForLiquidity = (sqrtRatioAX96, sqrtRatioBX96, liquidity) => {
  const liquidityX96 = Math.pow(2, 96) * liquidity;
  const amount0 = (liquidityX96 * (sqrtRatioBX96 - sqrtRatioAX96)) / sqrtRatioBX96 / sqrtRatioAX96;
  return amount0;
};

const getAmount1ForLiquidity = (sqrtRatioAX96, sqrtRatioBX96, liquidity) => {
  const Q96 = Math.pow(2, 96);
  const amount1 = (liquidity * (sqrtRatioBX96 - sqrtRatioAX96)) / Q96;
  return amount1;
};

export const getAmountsForLiquidity = (tick, tickLower, tickUpper, liquidity) => {
  const sqrtRatioX96 = TickMath.getSqrtRatioAtTick(Number(tick));
  let sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(tickLower));
  let sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(tickUpper));

  let amount0, amount1;

  // If tick is below range (tick < tickLower)
  if (sqrtRatioX96 <= sqrtRatioAX96) {
    amount0 = getAmount0ForLiquidity(sqrtRatioAX96, sqrtRatioBX96, liquidity);
  }

  // If tick is between range
  if (sqrtRatioX96 > sqrtRatioAX96 && sqrtRatioX96 < sqrtRatioBX96) {
    amount0 = getAmount0ForLiquidity(sqrtRatioX96, sqrtRatioBX96, liquidity);
    amount1 = getAmount1ForLiquidity(sqrtRatioAX96, sqrtRatioX96, liquidity);
  }

  // If tick is above range (tick > tickUpper)
  if (sqrtRatioX96 >= sqrtRatioBX96) {
    amount1 = getAmount1ForLiquidity(sqrtRatioAX96, sqrtRatioBX96, liquidity);
  }

  return { amount0, amount1 };
};

export const getV3PoolAddress = async (chain_id, token0Address, token1Address, token0Decimals, token1Decimals, fee) => {
  const tokenA = new Token(chain_id, token0Address, token0Decimals);
  const tokenB = new Token(chain_id, token1Address, token1Decimals);

  const POLYGON_CHAIN_ID = 137;
  const MUMBAI_CHAIN_ID = 80001;
  function constructSameAddressMap(address, additionalNetworks = []) {
    return [POLYGON_CHAIN_ID, MUMBAI_CHAIN_ID].concat(additionalNetworks).reduce((memo, chainId) => {
      memo[chainId] = address;
      return memo;
    }, {});
  }

  const V3_FACTORY_ADDRESS = '0x1F98431c8aD98523631AE4a59f267346ea31F984';
  const V3_CORE_FACTORY_ADDRESSES = constructSameAddressMap(V3_FACTORY_ADDRESS, [POLYGON_CHAIN_ID, MUMBAI_CHAIN_ID]);

  const poolAddress = computePoolAddress({
    factoryAddress: V3_CORE_FACTORY_ADDRESSES[chain_id],
    tokenA,
    tokenB,
    fee,
  });

  return poolAddress;
};

export const getSlot0 = async (poolAddress, signer) => {
  const POOL_STATE_INTERFACE = new Interface(IUniswapV3PoolStateABI.abi);
  const poolContract = new Contract(poolAddress, POOL_STATE_INTERFACE, signer);
  const slot0 = await poolContract.slot0();

  return slot0;
};

export const getPoolTick = async (
  chain_id,
  signer,
  token0Address,
  token1Address,
  token0Decimals,
  token1Decimals,
  fee,
) => {
  const poolAddress = await getV3PoolAddress(
    chain_id,
    token0Address,
    token1Address,
    token0Decimals,
    token1Decimals,
    fee,
  );
  const slot0 = await getSlot0(poolAddress, signer);

  return slot0.tick;
};
