const Config = {
  137: {
    uniswapSubGraph: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
    orbitSubGraph: 'https://api.thegraph.com/subgraphs/name/fel-developers/orbit',
  },
  80001: {
    uniswapSubGraph: 'https://api.thegraph.com/subgraphs/name/find-price/uniswap-v3-mumbai',
    orbitSubGraph: 'https://api.thegraph.com/subgraphs/name/fel-developers/orbit',
  },
  debug: process.env.REACT_APP_DEBUG === 'true',
};

export default Config;
