import { useState, useEffect } from 'react';
import { useSharedState } from '../../../context/store';
import { usePositions } from '../../../hooks/usePositions';
import networks from '../../../helpers/networks';
import PositionsList from '../import/PositionsList';

import PoolDetailsBox from './PoolDetailsBox';
import DetailCards from './DetailCards';
import HolderIcon from '../../icons/HolderIcon';
import '../home.scss';

export default function Dashboard() {
  const [{ account, provider, chain_id, positions, loading_state }] = useSharedState();
  const { getAllPositions } = usePositions();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (provider && chain_id in networks && loading_state !== 'positions') getAllPositions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  return (
    <>
      {account ? (
        <>
          {positions ? (
            <>
              {positions.length > 0 ? (
                <div className='row mt-4 gap-y-1 mx-auto'>
                  <div className='col-12 col-lg-8 p-0'>
                    <DetailCards positions={positions} />
                    <PositionsList
                      selected={selected}
                      setSelected={setSelected}
                      positions={positions}
                      colsXs={1}
                      colsMd={2}
                      page='dashboard'
                    />
                  </div>
                  <div className='col-lg-4 p-0'>
                    <PoolDetailsBox />
                  </div>
                </div>
              ) : (
                positions.length === 0 && (
                  <>
                    <div className='row mt-4 gap-y-1 mx-auto'>
                      <div className='col-12 col-lg-8 p-0'>
                        <DetailCards positions={undefined} />
                        <div className='w-full h-[466px] bg-white rounded-xl shadow-md p-[24px] my-4'>
                          <div className='w-full h-full grid place-items-center p-4 box-shadow window background-light-gray border-none rounded-xl'>
                            <div className='flex flex-col gap-2 items-center justify-center max-w-xs text-center mb-3'>
                              <HolderIcon />
                              <p className='azeret text-gray-500 font-medium text-lg'>
                                Your active positions will appear here
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 p-0'>
                        <PoolDetailsBox />
                      </div>
                    </div>
                  </>
                )
              )}
            </>
          ) : (
            <div className='row mt-4 gap-y-1 mx-auto'>
              <div className='col-12 col-lg-8 p-0'>
                <DetailCards positions={undefined} />
                <PositionsList
                  selected={0}
                  setSelected={setSelected}
                  positions={undefined}
                  colsXs={1}
                  colsMd={2}
                  page='dashboard'
                />
              </div>
              <div className='col-lg-4 p-0'>
                <PoolDetailsBox />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='bg-white text-center p-4 rounded-xl my-4'>
          <p className='text-lg azeret text-gray-700'>Please connect your wallet to get started.</p>
        </div>
      )}
    </>
  );
}
