import './Landing.scss';
import Footer from './Footer.js';
import Header from './Header.js';
import Hero from './Hero.js';
import Card from './Card.js';
import HowItWorks from './HowItWorks.js';
import BoxSection from './BoxSection.js';
import SolutionSection from './SolutionSection.js';
import FaqSection from './FaqSection.js';
import RoadMap from './RoadMap.js';
import orbitIcon from '../../images/orbit-logo-green.png';
import blogIcon from '../../images/blog.svg';
import discordIcon from '../../images/discord.svg';
import companyIcon from '../../images/five-elements-logo.svg';
import docsIcon from '../../images/docs.svg';
import twitterIcon from '../../images/twitter.svg';

export default function Landing() {
  return (
    <div className='w-full'>
      <div className='bg-sun lg:pb-24 md:pb-24'>
        <div className='container mx-auto'>
          <Header />
          <div className='max-w-6xl mx-auto sm:px-6 more-mb'>
            <div className='pb-10 md:pt-40 md:pb-16'>
              {/* Hero content */}
              <Hero />
            </div>
          </div>
          {/* Items */}
          <div className='grid md:grid-cols-1 lg:grid-cols-3 grid-cols-1 gap-8 place-items-center'>
            <Card
              title='Compound automatically'
              description='Reinvest fees in your position to maximize APY (+0.5-10%)* on your idle position.'
              backgroundColor='primary'
              titleColor='secondary'
            />
            <Card
              title='Deploy unused liquidity'
              description='When out of range, deploy assets to other protocol of choice or rebalance the positions.'
              backgroundColor='primary'
              titleColor='secondary'
            />
            <Card
              title='Enhanced usability'
              description='Enter and exit positions with one transaction and single tokens. Save gas fees.'
              backgroundColor='primary'
              titleColor='secondary'
            />
          </div>
        </div>
      </div>
      <div className='bg-mountains'>
        <div className='container mx-auto'>
          <HowItWorks />
          <BoxSection />
        </div>
      </div>
      <div className='bg-gradient pb-5'>
        <div className='container mx-auto'>
          <SolutionSection />
          <RoadMap />
          <FaqSection />
          <Footer
            textColor='secondary'
            orbitIcon={orbitIcon}
            companyIcon={companyIcon}
            twitterIcon={twitterIcon}
            discordIcon={discordIcon}
            docsIcon={docsIcon}
            blogIcon={blogIcon}
          />{' '}
        </div>
      </div>
    </div>
  );
}
