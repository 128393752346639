import Select from 'react-select';
import { useEffect, useState } from 'react';
import { useSharedState } from '../../../context/store';
import { useZapMint } from '../../../hooks/useZapMint';
import { useApprove } from '../../../hooks/useApprove';
import { useToken } from '../../../hooks/useToken';
import {
  getPriceByTick,
  getClosestAvailableTick,
  maxAmount,
  getSuggestedUpperTick,
  getSuggestedLowerTick,
} from '../../../utils/utils';
import { getPositionManager } from '../../../helpers/contracts';
import TokenImage from '../../utils/TokenImage';
import addresses from '../../../contracts/addresses';
import Config from '../../../Config';

export default function ZapSection() {
  const { zapMint } = useZapMint();
  const [{ account, chain_id, provider, selected_pool }] = useSharedState();
  const { checkAllowance, approveToken } = useApprove();
  const { getZapTokensInfo } = useToken();

  const [lowerSuggested, setLowerSuggested] = useState(0);
  const [upperSuggested, setUpperSuggested] = useState(0);
  const [positionData, setPositionData] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [tickData, setTickData] = useState({});
  const [zapToken, setZapToken] = useState();
  const [tokens, setTokens] = useState([]);

  const handleChange = (field, e) => setPositionData({ ...positionData, [field]: e });

  const handleChangeAmount = (e, tokenNumber) => {
    if (isNaN(e.target.value)) return;

    setPositionData({
      ...positionData,
      [`amount${tokenNumber}Desired`]: e.target.value,
    });
  };

  const selectMaxAmount = async (tokenAddress, tokenNumber) => {
    const max = await maxAmount(provider, tokenAddress);
    setPositionData({
      ...positionData,
      [`amount${tokenNumber}Desired`]: max,
    });
  };

  const approveTokens = async () => {
    try {
      const PositionManager = await getPositionManager(account, chain_id, await provider.getSigner());

      const _p1 = approveToken(zapToken.key, addresses[chain_id].DepositRecipes);
      const _p2 = approveToken(zapToken.key, PositionManager.address);
      await Promise.all([_p1, _p2]);

      setIsApproved(true);
    } catch (err) {
      console.error(err?.message);
    }
  };

  useEffect(() => {
    var isMounted = true;
    if (selected_pool?.token0Address && selected_pool?.token1Address && zapToken?.key) {
      const checkIsApproved = async () => {
        try {
          const PositionManager = await getPositionManager(account, chain_id, await provider.getSigner());

          const _p1 = checkAllowance(zapToken.key, addresses[chain_id].DepositRecipes);
          const _p2 = checkAllowance(zapToken.key, PositionManager.address);
          const [approved0, approved1] = await Promise.all([_p1, _p2]);

          if (!isMounted) return;
          setIsApproved(approved0 && approved1 && true);
        } catch (err) {
          console.error(err?.message);
        }
      };
      checkIsApproved();
    }

    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_pool?.token0Address, selected_pool?.token1Address, zapToken]);

  const zap = async () => {
    try {
      let { tickLower, tickUpper, amount0Desired } = positionData;
      if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(selected_pool?.tick, selected_pool?.feeTier, 10);
      if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(selected_pool?.tick, selected_pool?.feeTier, 10);
      const { token0Address, token1Address, feeTier } = selected_pool;

      const tokenId = await zapMint(
        token0Address,
        token1Address,
        amount0Desired,
        zapToken.key,
        tickLower,
        tickUpper,
        feeTier,
      );

      Config.debug && console.warn('MintAndDeposit::tokenId:', tokenId);
    } catch (err) {
      console.error(err?.message);
    }
  };

  const setSuggestedRange = async () => {
    setLowerSuggested(
      getPriceByTick(
        getSuggestedLowerTick(selected_pool?.tick, selected_pool?.feeTier, 10),
        true,
        selected_pool?.token0Decimals - selected_pool?.token1Decimals,
      ),
    );

    setUpperSuggested(
      getPriceByTick(
        getSuggestedUpperTick(selected_pool?.tick, selected_pool?.feeTier, 10),
        true,
        selected_pool?.token0Decimals - selected_pool?.token1Decimals,
      ),
    );
    setTickData({
      tickLower: lowerSuggested,
      tickUpper: upperSuggested,
    });
    setPositionData({
      ...positionData,
      [`tickLower`]: getSuggestedLowerTick(selected_pool?.tick, selected_pool?.feeTier, 10),
      [`tickUpper`]: getSuggestedUpperTick(selected_pool?.tick, selected_pool?.feeTier, 10),
    });
  };

  useEffect(() => {
    setPositionData({
      ...positionData,
      amount0Desired: '',
      amount1Desired: '',
    });
    selected_pool && setSuggestedRange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_pool]);

  useEffect(() => {
    selected_pool && setSuggestedRange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowerSuggested, upperSuggested]);

  useEffect(() => {
    var isMounted = true;
    const setZap = async () => {
      const allTokensArray = await getZapTokensInfo();
      const tokensArray = allTokensArray.filter(
        (token) =>
          token.tokenAddress.toLowerCase() !== selected_pool.token0Address.toLowerCase() &&
          token.tokenAddress.toLowerCase() !== selected_pool.token1Address.toLowerCase(),
      );

      let options = [];
      options.push({
        value: selected_pool.token0Address,
        key: selected_pool.token0Address,

        label: (
          <div className='flex'>
            <TokenImage token={selected_pool.token0.toLowerCase()} offsetSize='25px' offsetMarginLeft='0' />
            <p className='ml-2 text-black'>{selected_pool.token0.toUpperCase()}</p>
          </div>
        ),
      });
      options.push({
        value: selected_pool.token1Address,
        key: selected_pool.token1Address,

        label: (
          <div className='flex'>
            <TokenImage token={selected_pool.token1.toLowerCase()} offsetSize='25px' offsetMarginLeft='0' />
            <p className='ml-2 text-black'>{selected_pool.token1.toUpperCase()}</p>
          </div>
        ),
      });
      for (let i = 0; i < tokensArray.length; i++) {
        options.push({
          value: tokensArray[i].tokenAddress,
          key: tokensArray[i].tokenAddress,

          label: (
            <div className='flex'>
              <TokenImage token={tokensArray[i].tokenSymbol.toLowerCase()} offsetSize='25px' offsetMarginLeft='0' />
              <p className='ml-2 text-black'>{tokensArray[i].tokenSymbol.toUpperCase()}</p>
            </div>
          ),
        });
      }

      if (!isMounted) return;

      setTokens(options);
      setZapToken(options[0]);
    };
    setZap();

    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_pool]);

  // Reset amount on zapToken change
  useEffect(() => {
    setPositionData({
      ...positionData,
      amount0Desired: '',
      amount1Desired: '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zapToken]);

  const comingSoon = true;

  if (comingSoon)
    return (
      <div className='h-[43.8rem] w-full grid place-content-center'>
        <h3 className='text-lg azeret text-gray'>Coming soon!</h3>
      </div>
    );

  return (
    <>
      <div className='py-2 px-4 mt-4'>
        <div className='text-center md:text-left background-light-gray rounded-xl'>
          <h4 className='text-black text-lg text-center font-bold work-sans-bold primary pt-3 pb-1 uppercase'>
            Set price range
          </h4>
          {selected_pool ? (
            <p className='text-gray text-base text-center azeret pb-2'>
              Current Price:<br></br>{' '}
              <span className='primary font-bold'>
                {getPriceByTick(
                  Number(selected_pool?.tick),
                  true,
                  selected_pool?.token0Decimals - selected_pool?.token1Decimals,
                )}
                &nbsp;
                {selected_pool?.token0?.toUpperCase()}
              </span>{' '}
              per
              <span className='primary font-bold'> {selected_pool.token1.toUpperCase()}</span>
            </p>
          ) : (
            <div className='py-[26px]' />
          )}
        </div>
        <div className='flex items-bottom justify-between background-light-gray relative rounded-xl mt-3 border-primary border-2 pt-10 pb-1 px-3 w-full'>
          <button
            className={`absolute top-2 right-2 uppercase text-sm azeret text-white bg-gray-400 rounded-[9px] px-2 py-[2px]  ${
              tickData.tickLower === lowerSuggested ? 'bg-gray-400' : 'bg-second primary'
            }`}
            onClick={() => {
              setSuggestedRange();
              handleChange('tickLower', '');
              handleChange('tickUpper', '');
            }}
          >
            Suggested range
          </button>
          <p className='text-xl azeret-md primary uppercase'>Lower</p>
          <input
            type='text'
            value={tickData.tickLower || ''}
            onChange={(e) => {
              handleChange(
                'tickLower',
                e.target.value === '0' || e.target.value === '' || isNaN(e.target.value)
                  ? '0'
                  : getClosestAvailableTick(e.target.value, selected_pool?.feeTier),
              );
              setTickData({
                ...tickData,
                tickLower:
                  e.target.value === '0' || e.target.value === '' || isNaN(e.target.value) ? '0' : e.target.value,
              });
            }}
            placeholder={selected_pool ? lowerSuggested : '0'}
            className='text-gray text-xl text-right azeret hidden-input w-52'
          />
        </div>
        <div className='flex items-bottom justify-between background-light-gray relative rounded-xl mt-3 border-primary border-2 pt-10 pb-1 px-3 w-full'>
          <button
            className={`absolute top-2 right-2 uppercase text-sm azeret text-white rounded-[9px] px-2 py-[1px] ${
              tickData.tickUpper === upperSuggested ? 'bg-gray-400' : 'bg-second primary'
            }`}
            onClick={() => {
              setSuggestedRange();
              handleChange('tickLower', '');
              handleChange('tickUpper', '');
            }}
          >
            Suggested range
          </button>
          <p className='text-xl azeret-md primary uppercase'>Upper</p>
          <input
            type='text'
            value={tickData.tickUpper || ''}
            onChange={(e) => {
              handleChange(
                'tickUpper',
                e.target.value === '0' || e.target.value === '' || isNaN(e.target.value)
                  ? '0'
                  : getClosestAvailableTick(e.target.value, selected_pool?.feeTier),
              );
              setTickData({
                ...tickData,
                tickUpper:
                  e.target.value === '0' || e.target.value === '' || isNaN(e.target.value) ? '0' : e.target.value,
              });
            }}
            placeholder={selected_pool ? upperSuggested : '0'}
            className='text-gray text-xl text-right azeret hidden-input w-52'
          />
        </div>
        <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
          <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
            Deposit amounts
          </h4>
        </div>
        <div className='text-center md:text-left border-primary grid grid-cols-5 mx-auto py-2 gap-x-4'>
          <Select
            className='text-black azeret-md uppercase t-align-center text-md col-start-1 col-end-3 rounded-xl border-primary border-2 z-10  mt-auto h-3/5'
            classNamePrefix='text-center text-black azeret-md uppercase t-align-center text-xl z-10'
            menuPortalTarget={document.body}
            isSearchable={false}
            value={zapToken}
            onChange={(e) => {
              setZapToken(e);
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'transparent',
                zIndex: '1000',
              }),
              menu: (provided, state) => ({
                ...provided,
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'white',
                zIndex: '1000',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: 'white',
                color: 'black',
                zIndex: '1000',
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            options={tokens}
          />
          <div className='text-center md:text-left background-light-gray rounded-xl border-primary border-2 grid grid-cols-1 mx-auto py-2 px-3 col-span-3'>
            <button
              className={`purple-btn-xs azeret font-small rounded-xl mb-2 opacity pointer uppercase ${
                !selected_pool ? 'bg-gray-400 text-white' : 'bg-second primary'
              }`}
              onClick={() => selectMaxAmount(zapToken?.key, 0)}
            >
              Max
            </button>
            <input
              type='text'
              value={positionData?.amount0Desired?.toString() || '0'}
              onChange={async (e) => handleChangeAmount(e, 0)}
              placeholder='0'
              className='text-gray text-xl text-right azeret hidden-input'
            />
          </div>
        </div>

        <div className='row mx-auto'>
          {isApproved ? (
            <button
              className={`rounded-2xl btn-main azeret-md px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto uppercase ${
                !selected_pool ? 'bg-gray-400 text-white' : 'primary'
              }`}
              onClick={zap}
            >
              deposit
            </button>
          ) : (
            <button
              className={`rounded-2xl btn-main azeret-md px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto uppercase ${
                !selected_pool ? 'bg-gray-400 text-white' : 'primary'
              }`}
              onClick={approveTokens}
            >
              approve
            </button>
          )}
        </div>
      </div>
    </>
  );
}
