import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSharedState } from '../../../context/store';
import { usePositions } from '../../../hooks/usePositions';
import { useAnalytics } from '../../../hooks/useAnalytics';
import PositionsList from '../import/PositionsList';
import OrbitLoader from '../../icons/OrbitLoader';
import HolderIcon from '../../icons/HolderIcon';
import networks from '../../../helpers/networks';
import PositionLineChart from './PositionLineChart';
import PositionData from './PositionData';
import '../home.scss';

export default function Analytics() {
  const [{ account, provider, chain_id, loading_state, positions, analytics }] = useSharedState();
  const { getAllPositions } = usePositions();
  const { getAllAnalytics } = useAnalytics();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (provider && chain_id in networks && loading_state !== 'positions') getAllPositions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, chain_id]);

  useEffect(() => {
    if (positions?.length && selected) getAllAnalytics(selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, JSON.stringify(selected), pathname, loading_state]);

  return (
    <>
      {account ? (
        <>
          <div className=' pb-3'>
            <div className='row mt-6 gap-y-1 mx-auto'>
              <div className='col-12 col-md-4 p-0'>
                {(!positions || positions?.length === 0) && (
                  <div className='text-center md:text-left mb-3 p-1'>
                    <div
                      className={`${
                        positions?.length === 0 ? 'text-box-less-px bg-white' : 'p-0 shadow-md'
                      } rounded-2xl`}
                    >
                      <div
                        className={`${
                          positions?.length === 0 ? 'background-light-gray box-shadow p-2' : 'bg-white'
                        } border-none rounded-2xl mt-0`}
                      >
                        {!positions && (
                          <div className='w-full h-[586px] grid place-items-center'>
                            <div className='w-36'>
                              <OrbitLoader />
                            </div>
                          </div>
                        )}

                        {positions?.length === 0 && (
                          <div className='min-h-[535px] grid place-items-center'>
                            <div className='flex items-center gap-2 flex-col justify-center p-5'>
                              <HolderIcon />
                              <p className='text-xl text-center text-gray-500 font-medium'>
                                Your active positions will appear here
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {positions?.length > 0 && (
                    <div id='box-scrollbar' className='max-h-[590px] overflow-y-scroll'>
                      <PositionsList
                        selected={selected}
                        setSelected={setSelected}
                        positions={positions}
                        colsXs={1}
                        colsMd={1}
                        page='analytics'
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='mt-md-0 mt-4 col-md-8 p-0'>
                <div className='md:ml-4 grid gap-3'>
                  <div className='text-box bg-white'>
                    <div className='w-full h-[5rem]'>
                      <PositionData />
                    </div>
                  </div>
                  <div className='bg-white rounded-2xl p-0 shadow-lg'>
                    <div className='w-full h-[15.5rem] p-0'>
                      {analytics?.dailySnapshots?.length > 0 ? (
                        <PositionLineChart />
                      ) : (
                        <div className='w-full h-full grid place-content-center'>
                          <h2 className='text-gray azeret text-lg'>No available data 🔍</h2>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='text-box bg-white'>
                    <div className='w-full h-[12.8rem] grid place-content-center'>
                      <h2 className='text-gray azeret text-lg'>Coming soon! 📊</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='bg-white text-center p-4 rounded-xl my-4'>
          <p className='text-lg azeret text-gray-700'>Please connect your wallet to get started.</p>
        </div>
      )}
    </>
  );
}
