const addresses = {
  137: {
    ChainName: 'polygon',
    ChainExplorerUrl: 'https://polygonscan.com',
    NonFungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
    PositionManagerFactory: '0xB4F9F129d59bD634Fa98d2759ee8c92E9F840802',
    DepositRecipes: '0x08753881139b82F8cd6A5eA8fD3AecE2EEf345C5',
    WithdrawRecipes: '0xEf872Ad24deAD70BaFB194AE0d0f5CCf99d2429e',
    LendingPoolV2: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
    modules: {
      AutoCompoundModule: '0xB7f715cfc9cc6051695F58F8b08B6b6b3edfb509',
      IdleLiquidityModule: '0x35caD470A07f124aA3be13D73157ccE1246edbfb',
      AaveModule: '0x64EeE3D423fdD22e99c31c0e9eBC237c3b821AC5',
    },
    zapTokens: [
      '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', // WETH
      '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC
      '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', // WBTC
      // '0x0000000000000000000000000000000000001010', // MATIC
    ],
    image: 'polygon-logo.svg',
    shortName: 'Polygon',
  },
  // 80001: {
  //   ChainName: 'mumbai',
  //   ChainExplorerUrl: 'https://mumbai.polygonscan.com',
  //   NonFungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  //   PositionManagerFactory: '0xB717C31a9cF41485771e9c5D7CFCeF9Ca089532a',
  //   DepositRecipes: '0xDB16e52E16B0e4e31e36F204c227FcC38c7B6248',
  //   modules: {
  //     AutoCompoundModule: '0x1689ACDd716B4EBbd15c07f84021081a4d0b12ba',
  //     IdleLiquidityModule: '0x129c6ef6dbe67ec33002bd10b7817cdc0842A380',
  //     AaveModule: '0x129c6ef6dbe67ec33002bd10b7817cdc0842A380', // <-- Mocked address
  //   },
  //   image: 'eth.svg',
  //   shortName: 'Mumbai',
  // },
};

export default addresses;
