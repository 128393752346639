import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useWallet } from '../../hooks/useWallet';
import { useEvents } from '../../hooks/useEvents';

import WalletHeader from './WalletHeader';
import WalletFooter from './WalletFooter';
import BetaHeader from './BetaHeader';
import Navbar from './Navbar';
import Footer from '../landing/Footer';
import CreatePM from '../utils/CreatePM';
import LoadingModal from '../utils/LoadingModal';

import orbitIcon from '../../images/icon-gray.svg';
import companyIcon from '../../images/five-elements-logo-gray.svg';
import blogIcon from '../../images/blog-gray.svg';
import docsIcon from '../../images/docs-gray.svg';
import twitterIcon from '../../images/twitter-gray.svg';
import discordIcon from '../../images/discord-gray.svg';

export default function Layout() {
  const { autoLoginWallet } = useWallet();
  const location = useLocation();
  const [isResized, setIsResized] = useState(false);

  // Deploy Web3 event listeners
  useEvents();

  // Initial page load triggers
  useEffect(() => {
    (async () => {
      await autoLoginWallet();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle waves background animation
  const setWave = () => {
    const wave1 = document.getElementById('wave1');
    const wave2 = document.getElementById('wave2');

    wave1.classList.remove('wave');
    wave2.classList.remove('wave2');
    void wave1.offsetWidth;
    void wave2.offsetWidth;
    wave1.classList.add('wave');
    wave2.classList.add('wave2');
  };
  useEffect(() => setWave(), []);
  useEffect(() => setWave(), [isResized]);
  useEffect(() => {
    const handleResize = () => setIsResized(!isResized);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const handleLoad = () => setIsResized(!isResized);
    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  });

  return (
    <>
      <span className='d-none d-md-block ocean'>
        <span id='wave1' className='wave' />
        <span id='wave2' className='wave2' />
      </span>
      <BetaHeader />

      <div className='container pb-5 relative z-10'>
        <CreatePM />
        <LoadingModal />

        <WalletHeader />

        <Navbar currentPage={location.pathname.substring(1)} />

        <Outlet />

        <Footer
          className='d-none d-lg-block'
          textColor='primary'
          orbitIcon={orbitIcon}
          companyIcon={companyIcon}
          twitterIcon={twitterIcon}
          discordIcon={discordIcon}
          docsIcon={docsIcon}
          blogIcon={blogIcon}
        />
        <WalletFooter />
      </div>
    </>
  );
}
