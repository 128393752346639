import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { getWithdrawRecipes } from '../helpers/contracts';

export const useWithdraw = () => {
  const [{ provider, chain_id }] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const withdraw = async (ids, shares) => {
    try {
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);
      shares = shares.replace('%', '');

      setWalletActionRequired(true);
      const gasLimitEstimate = await WithdrawRecipes.estimateGas.withdrawUniNft(ids, shares * 100);
      const tx = await WithdrawRecipes.withdrawUniNft(ids, shares * 100, {
        gasLimit: gasLimitEstimate.add(150000),
      });
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Withdrawing your position');
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during withdraw');
      console.error(err?.message);
    }
  };

  return { withdraw };
};
