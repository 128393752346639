import { useEffect, useState } from 'react';
import { useDeposit } from '../../../hooks/useDeposit';
import PositionsList from './PositionsList';
import InfoIcon from '../../../images/info.svg';

export default function SmartVaultBox({ positions }) {
  const { deposit } = useDeposit();
  const [ids, setIds] = useState([]);

  useEffect(() => {
    const positionIds = positions.map((position) => position.tokenId);
    setIds(positionIds);
  }, [positions]);

  return (
    <div>
      <div className='p-2'>
        <div className='box-shadow window background-light-gray border-none overflow-auto p-2'>
          <PositionsList positions={positions} colsXs={1} colsMd={2} page='vault' />
        </div>
        <div className='row pt-4'>
          <div className='col-12 col-md-8'>
            <div className='flex-row flex'>
              <img src={InfoIcon} alt='info' width='38rem' height='auto' />
              <h3 className='pl-3 primary azeret font-medium text-sm text-left'>
                Press the plus button to add here liquidity pools you want to import.
              </h3>
            </div>
          </div>
          <div className='col-12 col-md-4 t-align-right'>
            <button
              className='rounded-xl btn-main px-5 px-md-3 px-lg-5 mr-0 ml-auto uppercase'
              onClick={async () => await deposit(ids)}
            >
              Deposit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
