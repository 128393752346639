import { useState, useEffect } from 'react';
import { usePools } from '../../../hooks/usePools';
import { useSharedState } from '../../../context/store';

import DepositBox from './DepositBox';
import SearchBar from './SearchBar';
import PoolsList from './PoolsList';
import OrbitLoader from '../../icons/OrbitLoader';

import '../home.scss';

export default function ProvideLiquidity() {
  const [{ uniswap_top_pools, chain_id, provider }] = useSharedState();
  const { getUniPools } = usePools();
  const [filteredPools, setFilteredPools] = useState();

  useEffect(() => {
    var isMounted = true;

    const getUniPoolsData = async () => {
      const pools = await getUniPools();
      if (!isMounted) return;
      setFilteredPools(pools.slice(0, pools.length));
    };

    if (chain_id && provider) getUniPoolsData();
    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain_id, provider]);

  return (
    <>
      <div className=' pb-5'>
        <div className='row mt-6 gap-y-1 mx-auto'>
          <div className='col-12 col-lg-6 p-0'>
            <SearchBar pools={uniswap_top_pools} setFilteredPools={setFilteredPools} filteredPools={filteredPools} />
            <div className='mr-1'>
              {filteredPools ? (
                <PoolsList itemsPerPage={10} items={filteredPools} />
              ) : (
                <div
                  id='loading-card'
                  className='overflow-hidden w-full h-[566px] bg-white grid place-items-center shadow-md rounded-xl'
                >
                  <div className='w-36 mb-3'>
                    <OrbitLoader />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mt-md-0 mt-4 col-lg-6 p-0'>
            <DepositBox />
          </div>
        </div>
      </div>
    </>
  );
}
