import { useEffect, useState } from 'react';

export default function BetaHeader() {
  const [headerContentBool, setHeaderContentbool] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setHeaderContentbool(!headerContentBool), 5000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!hidden && (
        <div className='text-center w-full text-sm font-medium betaHeader p-2 text-white flex justify-content'>
          {headerContentBool && (
            <p className='ml-auto text-sm font-medium'>This is BETA software - Use it at your own risk.</p>
          )}

          {!headerContentBool && (
            <p className='ml-auto text-sm font-medium'>
              If you encounter any bugs, please report them on{' '}
              <a className='underline text-sm font-medium' href='https://discord.gg/j8RgSsdFh9'>
                our Discord
              </a>
            </p>
          )}
          <button
            className='ml-auto'
            onClick={() => {
              setHidden(true);
            }}
          >
            <CloseHeader />
          </button>
        </div>
      )}
    </>
  );
}

const CloseHeader = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-6 w-6'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
      strokeWidth={2}
      {...props}
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
    </svg>
  );
};
