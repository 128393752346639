import { getERC20 } from '../helpers/contracts';
import { useSharedState } from '../context/store';
import { useLoading } from './useLoading';
import { BigNumber } from 'ethers';

export const useApprove = () => {
  const [{ account, provider }] = useSharedState();
  const { setWalletActionRequired, loadAsyncTx } = useLoading();

  // Approve 'recipientAddress' to spend 'tokenAddress' for 'account'
  const approveToken = async (tokenAddress, recipientAddress) => {
    try {
      const signer = await provider.getSigner();
      const token = getERC20(tokenAddress, signer);

      const tokenSymbol = await token.symbol();
      const tokenDecimals = await token.decimals();

      const amount = BigNumber.from(1e9).mul(BigNumber.from(10).pow(tokenDecimals)).toString();

      // Return if token is already approved
      if (await checkAllowance(tokenAddress, recipientAddress)) return;

      // Approve spending: recipient: DepositRecipes - spender: account
      setWalletActionRequired(true);
      const approval = await token.approve(recipientAddress, amount, { gasLimit: 200000 });
      setWalletActionRequired(false);

      await loadAsyncTx(approval, `Approving ${tokenSymbol}`);
    } catch (err) {
      setWalletActionRequired(false);
      console.error(err?.message);
    }
  };

  // Check 'account' allowance of 'tokenAddress' spending on 'recipientAddress'
  const checkAllowance = async (tokenAddress, recipientAddress) => {
    if (!recipientAddress || !tokenAddress) throw new Error("'recipientAddress' or 'tokenAddress' is undefined");

    try {
      const signer = await provider.getSigner();
      const token = getERC20(tokenAddress, signer);

      const tokenAllowance = await token.allowance(account, recipientAddress);
      const tokenDecimals = await token.decimals();

      const maxAmount = BigNumber.from(1e9).mul(BigNumber.from(10).pow(tokenDecimals));

      // Returns true if the allowance is at least the max amount
      return !tokenAllowance.lte(maxAmount.sub(maxAmount.mul(90).div(100)));
    } catch (err) {
      console.error(err?.message);
    }
  };

  return { checkAllowance, approveToken };
};
