import { useEffect, useState, useMemo } from 'react';
import { useSharedState } from '../../../context/store';
import { useWithdraw } from '../../../hooks/useWithdraw';
import { getAmountsForLiquidity, getV3PoolAddress, getSlot0 } from '../../../utils/utils';
import TokenImage from '../../utils/TokenImage';
import PoolImage from '../../utils/PoolImage';

export default function RegularSection() {
  const [{ chain_id, provider, selected_position }] = useSharedState();
  const { withdraw } = useWithdraw();

  const [sharesPerc, setSharesPerc] = useState();
  const [tick, setTick] = useState(0);
  const [amount0, setAmount0] = useState(0);
  const [amount1, setAmount1] = useState(0);

  const poolAddress = useMemo(
    () =>
      chain_id &&
      selected_position &&
      getV3PoolAddress(
        chain_id,
        selected_position.token0Address,
        selected_position.token1Address,
        selected_position.token0Decimals,
        selected_position.token1Decimals,
        selected_position.fee * 10000,
      ),
    [chain_id, selected_position],
  );

  useEffect(() => {
    var isMounted = true;
    (async () => {
      if (poolAddress) {
        const signer = await provider.getSigner();
        const slot0 = await getSlot0(poolAddress, signer);
        if (!isMounted) return;
        setTick(slot0.tick);
      }
    })();

    return () => (isMounted = false);
  }, [provider, poolAddress]);

  const handleShares = (shares) => {
    try {
      const liqToWithdraw = (shares / 100) * selected_position.liquidity;

      const { amount0: amount0ForLiq, amount1: amount1ForLiq } = getAmountsForLiquidity(
        tick,
        Number(selected_position.tickLower),
        Number(selected_position.tickUpper),
        liqToWithdraw,
      );

      setAmount0(amount0ForLiq / Math.pow(10, selected_position.token0Decimals));
      setAmount1(amount1ForLiq / Math.pow(10, selected_position.token1Decimals));
    } catch (err) {
      console.error(err?.message);
    }
  };

  const handleWithdrawAmount = (e) => {
    const value = e.target.value.replace('%', '');
    if (isNaN(value) || Number(value) > 100) return;

    e.target.value = value + '%';

    setSharesPerc(e.target.value);
    handleShares(e.target.value.replace('%', ''));
  };

  useEffect(() => {
    setAmount0(0);
    setAmount1(0);
    setSharesPerc('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selected_position)]);

  return (
    <>
      {selected_position && (
        <div className='py-2 px-4 mt-4'>
          <div className='text-center md:text-left background-light-gray rounded-xl'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
              You withdraw
            </h4>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='col-span-2 items-center'>
              <PoolImage
                token0={selected_position.token0.toLowerCase()}
                token1={selected_position.token1.toLowerCase()}
                offsetMarginLeft='-18px'
                size='45px'
                className='mx-auto'
              />
              <p className='text-md azeret-md text-gray self-center ml-2'>
                {selected_position.token0.toUpperCase()}-{selected_position.token1.toUpperCase()}
              </p>
            </div>
            <div className='text-center md:text-left background-light-gray rounded-xl border-primary border-2 flex flex-col justify-between py-2 px-3 col-span-3'>
              <button
                className='text-sm btn-main px-2 py-0 mr-0 ml-auto rounded-xl pointer leading-0 uppercase'
                onClick={() => {
                  setSharesPerc('100%');
                  handleShares('100');
                }}
              >
                Max
              </button>
              <input
                type='text'
                placeholder='20%'
                value={sharesPerc || ''}
                className='text-gray text-xl text-right azeret hidden-input'
                onChange={handleWithdrawAmount}
              />
            </div>
          </div>
          <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
              You receive
            </h4>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='flex col-span-2 items-center'>
              <TokenImage token={selected_position.token0.toLowerCase()} offsetMarginLeft='0' offsetSize='40px' />
              <p className='text-lg azeret-md text-gray self-center ml-2'>{selected_position.token0.toUpperCase()}</p>
            </div>
            <div className='text-center background-light-gray rounded-xl grid grid-cols-1 ml-2 mr-0 py-2 px-3 col-span-3'>
              <input
                type='text'
                value={amount0 ? amount0.toPrecision(4) : ''}
                placeholder='0'
                className='text-gray text-xl text-right azeret hidden-input'
                disabled
              />
            </div>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='flex col-span-2 items-center'>
              <TokenImage token={selected_position.token1.toLowerCase()} offsetMarginLeft='0' offsetSize='40px' />

              <p className='text-lg azeret-md text-gray self-center ml-2'>{selected_position.token1.toUpperCase()}</p>
            </div>
            <div className='text-center background-light-gray rounded-xl grid grid-cols-1 ml-2 mr-0 py-2 px-3 col-span-3'>
              <input
                type='text'
                value={amount1 ? amount1.toPrecision(4) : ''}
                placeholder='0'
                className='text-gray text-xl text-right azeret hidden-input'
                disabled
              />
            </div>
          </div>
          <div className='row mx-auto'>
            <button
              className='rounded-xl btn-main font-medium px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto uppercase'
              data-aos='fade-left'
              onClick={async () => await withdraw(Number(selected_position.id), sharesPerc)}
            >
              Withdraw
            </button>
          </div>
        </div>
      )}
    </>
  );
}
