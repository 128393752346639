export default function FaqSection() {
  return (
    <>
      <div className='md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center lg:pt-6 md:pt-6 sm:pt-4'>
        <div className='md:col-span-9 lg:col-span-9 mb-4 md:mb-0 max-w-6xl'>
          <h2 className='secondary lg:text-7xl text-5xl mb-4 work-sans-bold'>Why orbit?</h2>
        </div>
        <div className='md:col-span-3 lg:col-span-3 mb-0 md:mb-0 text-center md:text-left'></div>
      </div>
      <div className='place-items-center mb-8'>
        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              What is the main idea behind Orbit?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>
              As DeFi protocols become more complex and capital-efficient, professional and retail users need tools to
              operate across protocols and strategies effectively. Now that low fees blockchains are more and more
              popular, it is possible to build fast and effective automation tools on top of existing popular protocols.
              We started with Uniswap for the reasons detailed below.
            </p>
          </div>
        </div>

        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              What is concentrated liquidity, and why is it important?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>
              Concentrated liquidity is used by modern decentralized exchanges (dexes) to provide liquidity to traders
              in a capital-efficient way. It is called "concentrated" as liquidity providers have to decide ranges where
              to put capital to work.{' '}
            </p>

            <p>
              Concentrated liquidity is hard for users to understand and use, as it requires several operations.
              However, the concentrated liquidity exchanges are here to stay, as they provide much higher capital
              efficiency with respect to traditional passive AMMs.{' '}
            </p>
          </div>
        </div>
        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              What is Orbit?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>
              Orbit is an LP management platform to automate and optimize Uniswap positions. Users can decide to provide
              liquidity for any token pair at the desired range.
            </p>
            <p>
              Moreover, they can apply automatic strategies and operations to their assets, such as autocompound
              (reinvesting fees), auto-rebalance (open a new position in range), and idle liquidity (provide liquidity
              to other protocols when a position is out of range).
            </p>
          </div>
        </div>

        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              How it works?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>
              Orbit works through a smart vault system, where each user has his own smart contract that takes care of
              the hard work. Orbit's mission is to become the go-to platform for concentrated liquidity, helping
              professionals and casual users to exploit this new paradigm better. Users can apply their strategy of
              choice to their assets. The <a href="https://fiveelementslabs.gitbook.io/orbit/introduction/orbit" target='_blank' rel="noreferrer">docs</a> explain in greater detail the inner workings of the protocol.
            </p>
          </div>
        </div>
        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              What are the benefits for end-users?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>
              Automations provide a direct impact on position yield. For instance, autocompound provides an estimated
              additional yield ranging from 0.1% up to 10%, depending on the position accrued fees and the position
              lifetime. Idle liquidity (putting liquidity to work in other protocols while it is off range) provides
              1-5% additional yield, mitigating potential impermanent loss effects. Another benefit is to track and
              manage positions from a single source.
            </p>
          </div>
        </div>
        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              Is Orbit free?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>Orbit is free. We will pay for gas in the first version of the product.</p>
          </div>
        </div>
        <div className={'cardOpen bg-purple-2 border-secondary shadow'}>
          <div className='flex justify-between'>
            <h4
              className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold secondary'}
            >
              What is Orbit's advantage relative to competitors?
            </h4>
          </div>

          <div className={'text-lg pt-2 pb-2 text-white text-left azeret h-fit block text-light-gray'}>
            <p>
              Orbit does not provide active management (proved ineffective for Uniswap v3 positions) but tools for
              professional and retail traders to get more yield for any liquidity-providing position.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
