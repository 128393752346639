import { getPositionMetrics } from '../api/getPositionMetrics';
import { getProtocolTVL } from '../api/getProtocolTVL';
import { useSharedState } from '../context/store';
import actions from '../context/actions';

export const useAnalytics = () => {
  const [{ chain_id }, dispatch] = useSharedState();

  const getTotalTVL = async () => {
    try {
      const data = await getProtocolTVL(chain_id);
      return data.positionManagerFactory.protocolTVL;
    } catch (err) {
      console.error(err?.message);
      return 0;
    }
  };

  const getAllAnalytics = async (position) => {
    dispatch({ type: actions.SET_ANALYTICS, payload: { analytics: null } });
    try {
      if (!position?.id) return;

      const data = await getPositionMetrics(chain_id, position.id);
      if (!data?.position) return;

      const marketValue = data.position.marketValueUSD;
      const hodlValue = data.position.HODLValueUSD;
      const apr = null; // APR Not tracked on subgraph yet
      const generatedFees = data.position.feesGeneratedUSD;
      const dailySnapshots = data.positionDailySnapshots;

      dispatch({
        type: actions.SET_ANALYTICS,
        payload: { analytics: { marketValue, hodlValue, apr, generatedFees, dailySnapshots } },
      });
    } catch (err) {
      console.error(err?.message);
    }
  };

  return { getAllAnalytics, getTotalTVL };
};
